import React from 'react';
import bemCn from 'bem-cn';
import ReactPaginate from 'react-paginate';
import SvgChevronLeft from 'src/assets/svg/SvgChevronLeft.svg';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import './style.scss';

const b = bemCn('sniff-pagination');

const Next = () => {
    return (
        <div className={b('next')}>
            <div className={b('next_text')}>Next</div>
            <div className={b('next_svg')}>
                <SvgChevronRight />
            </div>
        </div>
    );
};

const Prev = () => {
    return (
        <div className={b('prev')}>
            <div className={b('prev_svg')}>
                <SvgChevronLeft />
            </div>
            <div className={b('prev_text')}>Previous</div>
        </div>
    );
};

const SniffPagination = ({ ...rest }) => {
    return (
        <div className={b()}>
            <ReactPaginate
                breakLabel="..."
                nextLabel={<Next />}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                previousLabel={<Prev />}
                pageClassName={b('item')}
                activeClassName={b('item_selected')}
                renderOnZeroPageCount={null}
                disableInitialCallback
                {...rest}
            />
        </div>
    );
};

export default SniffPagination;
