import React from 'react';
import bemCn from 'bem-cn';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import defSpotImg from '../../assets/img/blog/blog-placeholder.png?sizes[]=450,sizes[]=600';
import PictureSet from '../picture-set';
import './style.scss';

const b = bemCn('blog-article-simple-desktop');

const BlogArticleSimpleDesktop = ({ data, useStateSSR }) => {
    const thumbnail =
        data.thumbnail || data.img
            ? ImageHelper.getUrlFromOptions(data.thumbnail || data.img, `fit-in/${IMG_RESIZES.x0256}`)
            : defSpotImg;

    return (
        <a href={data.slug || data.url} className={b()}>
            <PictureSet
                imgs={thumbnail}
                className={b('thumbnail')}
                width={368}
                height={264}
                useState={useStateSSR}
                SSR={!!useStateSSR}
                alt={data.title + ' thumbnail'}
            />
            <h4 className={b('title')}>{data.title}</h4>
        </a>
    );
};

export default BlogArticleSimpleDesktop;
